// import React from "react";
// import { Link } from "react-router-dom";

// const AccountVerifiedPage = () => {
//   return (
//     <>
//       <div className="highlight-msg">
//         <Link to={"/"} className="login-form-logo">
//           <img src="assets/image/login-logo.png" alt="project logo" />
//         </Link>
//         <div className="text">
//           <h1 className="success-color" style={{ color: "green" }}>
//             Success !!
//           </h1>
//           <p>
//             Your account has been verified successfully, you can now login to
//             your account.
//           </p>
//           <div className="text-center mt-3">
//             {/* <Link to={"/login"} className="login-btn">
//               Login
//             </Link> */}
//             <button
//               className="login-btn"
//               onClick={() => {
//                 window.location.href = "/login";
//               }}
//             >
//               Login
//             </button>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

// export default AccountVerifiedPage;

// import React from "react";
// import { Link, useLocation } from "react-router-dom";

// const AccountVerifiedPage = () => {
//   const location = useLocation();
//   const { message, success } = location.state || {};

//   return (
//     <div className="highlight-msg">
//       <Link to={"/"} className="login-form-logo">
//         <img src="assets/image/login-logo.png" alt="project logo" />
//       </Link>
//       <div className="text">
//         <h1
//           className={success ? "success-color" : "error-color"}
//           style={{ color: "green" }}
//         >
//           {success ? "Success !!" : "Already Activated"}
//         </h1>
//         <p>{message}</p>
//         <div className="text-center mt-3">
//           <button
//             className="login-btn"
//             onClick={() => {
//               window.location.href = "/login";
//             }}
//           >
//             Login
//           </button>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AccountVerifiedPage;

import React from "react";
import { Link, useLocation } from "react-router-dom";
import { getLabelList } from "../store/Action/PageAction";
import { useDispatch, useSelector } from "react-redux";

const AccountVerifiedPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { message, code } = location.state || {};

  const { labelListData } = useSelector((state) => state.labelList);

  const accountVerifiedPath =
    location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: accountVerifiedPath,
      })
    );
  }, [dispatch, accountVerifiedPath]);

  const successMessagetitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_ACCOUNT_VERIFIED_SUCCESS_MESSAGE"
    )?.vText;

  const accountVerifiedMessage =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_ACCOUNT_VERIFIED_VERIFIED_MESSAGE"
    )?.vText;

  const accountAldreadyVerifiedSuccessMessage =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_ACCOUNT_ALREADY_VERIFIED_SUCCESS_MESSAGE"
    )?.vText;

  const accountAldreadyVerifiedMessage =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_ACCOUNT_VERIFIED_ALREADY_ACTIVATED_MSG"
    )?.vText;

  return (
    <div className="highlight-msg">
      <Link to={"/"} className="login-form-logo">
        <img src="assets/image/login-logo.png" alt="project logo" />
      </Link>
      <div className="text">
        <h1 className="success-color" style={{ color: "green" }}>
          {code === 200
            ? successMessagetitle
            : accountAldreadyVerifiedSuccessMessage}
        </h1>
        <p>
          {code === 200
            ? accountVerifiedMessage
            : accountAldreadyVerifiedMessage}
        </p>
        <div className="text-center mt-3">
          <button
            className="login-btn"
            onClick={() => {
              window.location.href = "/login";
            }}
          >
            Login
          </button>
        </div>
      </div>
    </div>
  );
};

export default AccountVerifiedPage;
