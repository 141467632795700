import React from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getResetPassword,
  ResetPasswordState,
} from "../../store/Action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/Action/PageAction";

const ResetPassword = () => {
  const location = useLocation();
  const { vUniqueCode } = useParams();
  const toast = React.useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const { ResetPasswordData } = useSelector((state) => state.resetPassword);

  const { labelListData } = useSelector((state) => state.labelList);

  const pathSegments = location.pathname.split("/");
  const resetPasswordPath = pathSegments[1];

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value.trim();
    setPassword(passwordValue);

    event.target.value.trim() !== "" && setPasswordError("");

    if (passwordValue.trim() === "" && passwordValue.length === 0) {
      setPasswordError("Password is required.");
    } else if (passwordValue.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
    } else {
      setPasswordError("");
    }

    if (confirmPassword && passwordValue !== confirmPassword) {
      setConfirmPasswordError("Password and Confirm Password do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const confirmPasswordValue = event.target.value.trim();
    setConfirmPassword(confirmPasswordValue);

    event.target.value.trim() !== "" && setConfirmPasswordError("");

    if (
      confirmPasswordValue.trim() === "" &&
      confirmPasswordValue.length === 0
    ) {
      setConfirmPasswordError("Confirm Password  is required.");
    } else if (confirmPasswordValue.length < 6) {
      setConfirmPasswordError(
        "Confirm Password  must be at least 6 characters."
      );
    } else if (confirmPasswordValue !== password) {
      setConfirmPasswordError("Password and Confirm Password do not match.");
    } else {
      setConfirmPasswordError("");
    }
  };

  const validateResetPasswordForm = React.useCallback(() => {
    let isValid = true;

    setPasswordError("");
    setConfirmPasswordError("");

    if (password.length === 0) {
      setPasswordError("Pasword is Required.");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
      isValid = false;
    } else if (password !== confirmPassword) {
      setConfirmPasswordError("Password and Confirm Password do not match.");
      isValid = false;
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError("Confirm Pasword is Required.");
      isValid = false;
    } else if (confirmPassword.length < 6) {
      setConfirmPasswordError(
        "Confirm Password must be at least 6 characters."
      );
      isValid = false;
    } else if (confirmPassword !== password) {
      setConfirmPasswordError("Password and Confirm Password do not match.");
      isValid = false;
    }
    return isValid;
  }, [password, confirmPassword]);

  const ResetPassword = React.useCallback(
    (e) => {
      e.preventDefault();

      const isValideData = validateResetPasswordForm();

      if (isValideData) {
        dispatch(
          getResetPassword({
            vPassword: password,
            vConfirmPassword: confirmPassword,
            vUniqueCode: vUniqueCode,
          })
        );
      }
    },
    [
      dispatch,
      password,
      confirmPassword,
      vUniqueCode,
      validateResetPasswordForm,
    ]
  );

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: resetPasswordPath,
      })
    );
  }, [dispatch, resetPasswordPath]);

  const restPasswordFormTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_RESET_PASSWORD_RIGHT_TITLE"
    )?.vText;

  const ResetPasswordBannerTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_RESET_PASSWORD_LEFT_TITLE"
    )?.vText;

  const ResetPasswordBannerSubTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_RESET_PASSWORD_LEFT_SUBTITLE"
    )?.vText;

  const resetPasswordToastMessage =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_RESET_PASSWORD_TOAST_MESSAGE"
    )?.vText;

  React.useEffect(() => {
    if (ResetPasswordData && ResetPasswordData.code === 200) {
      toast.current.show({
        severity: "success",
        summary: resetPasswordToastMessage,
        life: 2000,
        className: "custom-toast",
      });

      setLoading(true);
      dispatch(ResetPasswordState());

      setTimeout(() => {
        setPassword("");
        setConfirmPassword("");
        setLoading(false);
        navigate("/login");
        window.location.reload();
      }, 2000);
    } else if (ResetPasswordData && ResetPasswordData.code === 404) {
      setLoading(true);
      dispatch(ResetPasswordState());
      toast.current.show({
        severity: "error",
        summary: "New password cannot be the same as the old password.",
        life: 2000,
        className: "custom-toast-error",
      });

      setTimeout(() => {
        setPassword("");
        setConfirmPassword("");
        setLoading(false);
      }, 2000);
    }
  }, [ResetPasswordData, dispatch, navigate, resetPasswordToastMessage]);

  return (
    <section className="login">
      <Toast ref={toast} />
      <div className="container">
        <div className="row g-0">
          <div className="col-lg-6">
            <div className="login-img">
              <img
                src="../assets/image/how-it-work-img.jpg"
                alt="howitworkimage"
                width="800"
                height="791"
              />
              <div className="login-img-content">
                <h1 className="title">{ResetPasswordBannerTitle}</h1>
                <p className="desc">{ResetPasswordBannerSubTitle}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="login-right-view">
              <div className="login-form-logo">
                <Link to="/">
                  <img
                    src="../assets/image/login-logo.png"
                    alt="loginlogoimage"
                    width="296"
                    height="68"
                  />
                </Link>
              </div>
              <div className="login-form-content">
                <h2 className="title" style={{ fontSize: "25px" }}>
                  {restPasswordFormTitle}
                </h2>
              </div>
              <form onSubmit={ResetPassword}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputpassword"
                        className="form-label"
                      >
                        New Password
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter New Password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <i
                        className={`toggle-password far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={togglePasswordVisibility}
                      ></i>
                      {passwordError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {passwordError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputConfirmpassword"
                        className="form-label"
                      >
                        Confirm New Password
                      </label>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter Confirm New Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <i
                        className={`toggle-password far ${
                          showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={toggleConfirmPasswordVisibility}
                      ></i>
                      {confirmPasswordError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {confirmPasswordError}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="login-btn-view">
                      {loading ? (
                        <>
                          {" "}
                          <button className="login-btn" type="button" disabled>
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button type="submit" className="login-btn">
                            Reset Password
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
