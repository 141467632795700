import React from "react";
import { Link, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getLabelList } from "../store/Action/PageAction";

const SuccessResetPasswordPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { labelListData } = useSelector((state) => state.labelList);

  const resetPasswordPath =
    location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: resetPasswordPath,
      })
    );
  }, [dispatch, resetPasswordPath]);

  const resetpasswordSuccessTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_SUCESS_REST_PASS_TITLE"
    )?.vText;

  const resetpasswordSuccessSubTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_SUCESS_REST_PASS_SUB_TITLE"
    )?.vText;
  return (
    <>
      <div className="highlight-msg">
        <Link to={"/"} className="login-form-logo">
          <img src="assets/image/login-logo.png" alt="project logo" />
        </Link>
        <div className="text">
          <h1 className="success-color" style={{ color: "green" }}>
            {resetpasswordSuccessTitle}
          </h1>
          <p>{resetpasswordSuccessSubTitle}</p>
          <div className="text-center mt-3">
            <Link to={"/login"} className="login-btn">
              Continue
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuccessResetPasswordPage;
