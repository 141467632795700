// import { SURVEY_BANNER_LIST_SUCCESS } from "../Constatnts/BannerConstant";
import {
  CHECK_INVITATION_TYPE_LIST_FAILURE,
  CHECK_INVITATION_TYPE_LIST_REQUEST,
  CHECK_INVITATION_TYPE_LIST_SUCCESS,
  // CLEAR_SURVEY_UNIQUE_CODE,
  CLEAR_UNIQUE_CODE,
  INVITATION_LIST_FAILURE,
  INVITATION_LIST_REQUEST,
  INVITATION_LIST_SUCCESS,
  // PARTICIPANT_ENTRY_LIST_FAILURE,
  PARTICIPANT_ENTRY_LIST_REQUEST,
  PARTICIPANT_ENTRY_LIST_SUCCESS,
  // PARTICIPANT_SKIP_ENTRY_LIST_FAILURE,
  PARTICIPANT_SKIP_ENTRY_LIST_REQUEST,
  PARTICIPANT_SKIP_ENTRY_LIST_SUCCESS,
  // SET_SURVEY_UNIQUE_CODE,
  SET_UNIQUE_CODE,
  STORE_SURVEY_FORM_RESET,
  // SURVEY_ANSWER_LIST_FAILURE,
  SURVEY_ANSWER_LIST_REQUEST,
  SURVEY_ANSWER_LIST_SUCCESS,
  SURVEY_LIST_FAILURE,
  SURVEY_LIST_REQUEST,
  SURVEY_LIST_SUCCESS,
  // SURVEY_PARTICIPANTDATA_LIST_FAILURE,
  // SURVEY_PARTICIPANTDATA_LIST_SUCCESS,
  // SURVEY_PUBLIC_INVITE_LIST_FAILURE,
  SURVEY_PUBLIC_INVITE_LIST_REQUEST,
  SURVEY_PUBLIC_INVITE_LIST_SUCCESS,
  // SURVEY_QUESTION_LIST_FAILURE,
  SURVEY_QUESTION_LIST_REQUEST,
  SURVEY_QUESTION_LIST_SUCCESS,
  TOTAL_INVITATION_LIST_FAILURE,
  TOTAL_INVITATION_LIST_REQUEST,
  TOTAL_INVITATION_LIST_SUCCESS,
  TOTAL_ORGANISATION_COUNT_LIST_FAILURE,
  TOTAL_ORGANISATION_COUNT_LIST_REQUEST,
  TOTAL_ORGANISATION_COUNT_LIST_SUCCESS,
  TOTAL_RESEARCHER_COUNT_LIST_FAILURE,
  TOTAL_RESEARCHER_COUNT_LIST_REQUEST,
  TOTAL_RESEARCHER_COUNT_LIST_SUCCESS,
  TOTAL_SURVEY_LIST_FAILURE,
  TOTAL_SURVEY_LIST_REQUEST,
  TOTAL_SURVEY_LIST_SUCCESS,
} from "../Constatnts/SurveyConstant";
import axios from "axios";

export const getSurveyList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/survey/get_all_data`,
      //   data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      data
    );

    dispatch({ type: SURVEY_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: SURVEY_LIST_FAILURE });
  }
};

export const getInvitationList = (data) => async (dispatch) => {
  try {
    dispatch({ type: INVITATION_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/invitation/get_all_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: INVITATION_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    // console.log(err)
    dispatch({ type: INVITATION_LIST_FAILURE });
  }
};

export const getSurveyCountList = (data) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_SURVEY_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/survey/count`,

      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      data
    );

    dispatch({ type: TOTAL_SURVEY_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: TOTAL_SURVEY_LIST_FAILURE });
  }
};

export const getInvitationCountList = (data) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_INVITATION_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/invitation/count`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({ type: TOTAL_INVITATION_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: TOTAL_INVITATION_LIST_FAILURE });
  }
};

export const getOrganisationCountList = (data) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_ORGANISATION_COUNT_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/acedemicResearcher/organization/count`,

      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      data
    );

    dispatch({
      type: TOTAL_ORGANISATION_COUNT_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: TOTAL_ORGANISATION_COUNT_LIST_FAILURE });
  }
};

export const getResearcherCountList = (data) => async (dispatch) => {
  try {
    dispatch({ type: TOTAL_RESEARCHER_COUNT_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/acedemicResearcher/researcher/count`,

      {
        headers: {
          "Content-Type": "application/json",
        },
      },
      data
    );

    dispatch({
      type: TOTAL_RESEARCHER_COUNT_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: TOTAL_RESEARCHER_COUNT_LIST_FAILURE });
  }
};

export const getSurveyPublicInviteList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_PUBLIC_INVITE_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/survey/public_invite_check`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: SURVEY_PUBLIC_INVITE_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    // dispatch({ type: SURVEY_PUBLIC_INVITE_LIST_FAILURE });
    dispatch({
      type: SURVEY_PUBLIC_INVITE_LIST_SUCCESS,
      payload: err?.response?.data,
    });
  }
};

export const getParticipantEntryList = (data) => async (dispatch) => {
  try {
    dispatch({ type: PARTICIPANT_ENTRY_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/participant_entry`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: PARTICIPANT_ENTRY_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
   
    // dispatch({ type: PARTICIPANT_ENTRY_LIST_FAILURE });
    dispatch({
      type: SURVEY_PUBLIC_INVITE_LIST_SUCCESS,
      payload: err?.response?.data,
    });
  }
};

export const ResetSurveyFormState = () => async (dispatch) => {
  dispatch({ type: STORE_SURVEY_FORM_RESET });
};

export const getParticipantSkipEntryList = (data) => async (dispatch) => {
  try {
    dispatch({ type: PARTICIPANT_SKIP_ENTRY_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/participant/skip_entry`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: PARTICIPANT_SKIP_ENTRY_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
 
    // dispatch({ type: PARTICIPANT_SKIP_ENTRY_LIST_FAILURE});
    dispatch({
      type: PARTICIPANT_SKIP_ENTRY_LIST_SUCCESS,
      payload: err?.response,
    });
  }
};

export const getSurveyQuestionList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_QUESTION_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/question/get_all_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: SURVEY_QUESTION_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    // dispatch({ type: SURVEY_QUESTION_LIST_FAILURE});
    dispatch({
      type: SURVEY_QUESTION_LIST_SUCCESS,
      payload: err?.response,
    });
  }
};

export const getSurveyAnswerList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SURVEY_ANSWER_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/question/answer`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: SURVEY_ANSWER_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    // dispatch({ type: SURVEY_ANSWER_LIST_FAILURE});
    dispatch({
      type: SURVEY_ANSWER_LIST_SUCCESS,
      payload: err?.response,
    });
  }
};

export const setUniqueCode = (uniqueCode) => {
  return {
    type: SET_UNIQUE_CODE,
    payload: uniqueCode,
  };
};

export const clearUniqueCode = () => {
  return {
    type: CLEAR_UNIQUE_CODE,
  };
};

export const getCheckInvitationTypeList = (data) => async (dispatch) => {
  try {
    dispatch({ type: CHECK_INVITATION_TYPE_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/invitation/check_invite_type`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    dispatch({
      type: CHECK_INVITATION_TYPE_LIST_SUCCESS,
      payload: resData.data,
    });
  } catch (err) {
    dispatch({ type: CHECK_INVITATION_TYPE_LIST_FAILURE });
    dispatch({
      type: CHECK_INVITATION_TYPE_LIST_SUCCESS,
      payload: err?.response,
    });
  }
};
