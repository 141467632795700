import {
  LABEL_LIST_FAILURE,
  LABEL_LIST_REQUEST,
  LABEL_LIST_SUCCESS,
  PAGE_LIST_FAILURE,
  PAGE_LIST_REQUEST,
  PAGE_LIST_SUCCESS,
} from "../Constatnts/PageConstant";

export function PageReducer(state = {}, action) {
  switch (action.type) {
    case PAGE_LIST_REQUEST:
      return {
        loading: true,
      };
    case PAGE_LIST_SUCCESS:
      return {
        loading: false,
        pageListData: action.payload,
      };
    case PAGE_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}

export function LabelReducer(state = {}, action) {
  switch (action.type) {
    case LABEL_LIST_REQUEST:
      return {
        loading: true,
      };
    case LABEL_LIST_SUCCESS:
      return {
        loading: false,
       labelListData: action.payload,
      };
    case LABEL_LIST_FAILURE:
      return {
        loading: false,
      };
    default:
      return state;
  }
}
