import React, { useState } from "react";
import Header from "../Layouts/Header";
import { useNavigate } from "react-router-dom";

const Organisation6 = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = useState(localStorage.getItem("User_Email") || "");
  const [address, setAddress] = useState(
    localStorage.getItem("User_Address") || ""
  );
  const [emailError, setEmailError] = useState("");
  const [addressError, setAddressError] = useState("");

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    localStorage.setItem("User_Email", newEmail);

    // Validate email format
    if (newEmail && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(newEmail)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };
  const handleAddressChange = (e) => {
    const newAddress = e.target.value;
    setAddress(newAddress);
    localStorage.setItem("User_Address", newAddress);

    // Validate address
    if (newAddress.length > 0 && newAddress.length < 5) {
      setAddressError("Address must be at least 5 characters long.");
    } else {
      setAddressError("");
    }
  };

  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const ValidateUserForm = React.useCallback(() => {
    let isValid = true;

    // Reset error messages
    setEmailError("");

    if (email && !isValidEmail(email)) {
      setEmailError("Invalid email format.");
      isValid = false;
    } else {
      setEmailError("");
    }
    if (address.length > 0 && address.length < 5) {
      setAddressError("Address must be at least 5 characters long.");
    } else {
      setAddressError("");
    }

    return isValid;
  }, [email, isValidEmail]);

  const handleSubmitClick = () => {
    const isValidData = ValidateUserForm();

    if (email || address) {
      if (isValidData) {
        setLoading(true);
        setTimeout(() => {
          navigate("/survey/thank-you");
        }, 2000);
      }
    } else {
      setLoading(true);
      setTimeout(() => {
        navigate("/survey/thank-you");
      }, 2000);
    }
  };
  return (
    <>
      <Header />
      <div className="questions-wrp survey-category questions2 survey-page">
        <div className="container">
          <div className="survey-content questions6">
            <p>
              At this time, we are interested in receiving feedback from
              researchers who have engaged with community-based organisations in
              the past 5 years . If you do intend to conduct such research in
              the near future and/or are interested in joining our mailing list,
              please enter your contact email address below
            </p>
            <div className="row g-4 mt-1">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="qus-title mb-2" htmlFor="email">
                    Your email
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Email Address"
                    onChange={handleEmailChange}
                    value={email}
                  />
                  {emailError && (
                    <div
                      className="error-msg"
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      {emailError}
                    </div>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="qus-title mb-2" htmlFor="email">
                    Your Address
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Your Address"
                    onChange={handleAddressChange}
                    value={address}
                  />
                  {addressError && (
                    <div
                      className="error-msg"
                      style={{
                        color: "red",
                        marginLeft: "5px",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      {addressError}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {loading ? (
              <>
                {" "}
                <button className="login-btn mt-4" type="button" disabled>
                  <span
                    className="spinner-border spinner-border-sm me-1"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Submiting...
                </button>
              </>
            ) : (
              <>
                <button
                  className="login-btn mt-4"
                  type="submit"
                  onClick={handleSubmitClick}
                >
                  Submit
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Organisation6;
