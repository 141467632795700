import React from "react";
import Header from "../Layouts/Header";
import { useDispatch, useSelector } from "react-redux";
import { getSurveyQuestionList } from "../../store/Action/SurveyAction";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";

const QuestionThree = () => {
  const questionRefs = useRef({});
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { state } = location;

  const vUniqueCode = state?.vUniqueCode || null;
  const SurveyId = state?.SurveyId || null;
  const SurveyType = state?.SurveyType || null;
  const participantvUniqueCodeNew = localStorage.getItem(
    "participantvUniqueCodeNew"
  );

  const [buttonLoading, setButtonLoading] = React.useState(false);
  const [pageNumber, setPageNumber] = React.useState(
    Number(localStorage.getItem(`pageNumber_${SurveyType}`)) || 1
  );
  const [errors, setErrors] = React.useState({});
  const [email, setEmail] = React.useState("");
  const [emailError, setEmailError] = React.useState("");

  const [hasScrolled, setHasScrolled] = React.useState(false);

  const [inputValues, setInputValues] = React.useState({});

  const optionText = localStorage.getItem(`OptionText_${SurveyType}`);

  const [selectedCategories, setSelectedCategories] = React.useState(
    JSON.parse(localStorage.getItem(`selectedCategories_${SurveyType}`)) || {}
  );

  const { surveyQuestionData, loading } = useSelector(
    (state) => state.surveyQuestion
  );
  const participantSurveyId = surveyQuestionData?.data?.Data?.[0]?.iSurveyId;

  const userUniqueCode = localStorage.getItem("vUniqueCode");

  const participantUniqueCode = localStorage.getItem("participantUniqueCode");

  // const OptionValue1 = localStorage.getItem(`Other1_${SurveyType}`);
  // const OptionValue2 = localStorage.getItem(`Other2_${SurveyType}`);
  // const OptionValue3 = localStorage.getItem(`Other3_${SurveyType}`);
  const isChecked = localStorage.getItem("isChecked");

  const TotalPage = surveyQuestionData?.data?.totalPages;

  React.useEffect(() => {
    localStorage.setItem(
      `selectedCategories_${SurveyType}`,
      JSON.stringify(selectedCategories)
    );
  }, [selectedCategories, SurveyType]);

  React.useEffect(() => {
    const savedEmail = localStorage.getItem(`email_${SurveyType}`);
    if (savedEmail) {
      setEmail(savedEmail);
    }
  }, [SurveyType]);

  React.useEffect(() => {
    localStorage.setItem(`pageNumber_${SurveyType}`, pageNumber);
  }, [pageNumber, SurveyType]);

  //new code //
  const renderQuestionContent = (item, index) => {
    switch (item.eType) {
      case "Table":
        return renderTableQuestion(item, index);
      case "Multi Select":
        return renderMultiSelectQuestion(item, index);
      case "Single Select":
        return renderSingleSelectQuestion(item, index);
      case "Dropdown":
        return renderDropdownQuestion(item, index);
      case "Description":
        return renderDescriptionQuestion(item, index);
      case "Range":
        return renderRangeQuestion(item, index);
      case "Custom":
        return renderCustomQuestion(item, index);
      default:
        return renderTextInputQuestion(item, index);
    }
  };

  const getInputValueFromLocalStorage = (
    questionId,
    categoryId,
    subCategoryId
  ) => {
    const storedData =
      JSON.parse(localStorage.getItem(`selectedCategories_${SurveyType}`)) ||
      {};
    const categoryData = storedData[questionId]?.find((cat) => cat[categoryId]);

    if (categoryData) {
      const subCategoryData = categoryData[categoryId]?.find(
        (subCategory) => subCategory[subCategoryId] !== undefined
      );
      return subCategoryData ? subCategoryData[subCategoryId] : "";
    }
    return ""; // Return an empty string if no value found
  };

  const handleOtherInputChange = (
    AnswerValue,
    questionId,
    categoryId,
    subCategoryId
  ) => {
    if (AnswerValue.length > 0) {
      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        delete updatedErrors[questionId];
        return updatedErrors;
      });
    }

    const existingData =
      JSON.parse(localStorage.getItem(`selectedCategories_${SurveyType}`)) ||
      {};

    if (!existingData[questionId]) {
      return;
    }

    const category = existingData[questionId].find((cat) => cat[categoryId]);

    if (!category) {
      return;
    }

    const subCategoryIndex = category[categoryId].findIndex(
      (subCategory) => subCategory[subCategoryId] !== undefined
    );

    if (subCategoryIndex !== -1) {
      if (AnswerValue) {
        category[categoryId][subCategoryIndex][subCategoryId] = AnswerValue;
      } else {
        category[categoryId].splice(subCategoryIndex, 1);
      }
    } else if (AnswerValue) {
      category[categoryId].push({ [subCategoryId]: AnswerValue });
    }

    if (category[categoryId].length === 0) {
      const categoryIndex = existingData[questionId].findIndex(
        (cat) => cat[categoryId]
      );
      existingData[questionId].splice(categoryIndex, 1);
    }

    if (existingData[questionId].length === 0) {
      delete existingData[questionId];
    }

    localStorage.setItem(
      `selectedCategories_${SurveyType}`,
      JSON.stringify(existingData)
    );

    setSelectedCategories(existingData);
  };

  // const handleCategorySelection = (questionId, categoryId) => {
  //   setSelectedCategories((prevSelected) => {
  //     const isCategoryCurrentlySelected = prevSelected[categoryId]?.selected;

  //     const updatedCategories = {
  //       ...prevSelected,
  //       [categoryId]: {
  //         ...prevSelected[categoryId],
  //         selected: !isCategoryCurrentlySelected,
  //         subCategories: isCategoryCurrentlySelected
  //           ? {}
  //           : prevSelected[categoryId]?.subCategories || {},
  //       },
  //     };

  //     const isAnyCategorySelected = Object.values(updatedCategories).some(
  //       (category) => category.selected
  //     );
  //     if (!SurveyType === "Organisation") {
  //       setErrors((prevErrors) => ({
  //         ...prevErrors,
  //         [questionId]: isAnyCategorySelected
  //           ? ""
  //           : "Please select at least one category.",
  //       }));
  //     }

  //     return updatedCategories;
  //   });
  // };

  const handleCategorySelection = (questionId, categoryId) => {
    setSelectedCategories((prevSelected) => {
      const isCategoryCurrentlySelected = prevSelected[categoryId]?.selected;

      const updatedCategories = {
        ...prevSelected,
        [categoryId]: {
          ...prevSelected[categoryId],
          selected: !isCategoryCurrentlySelected,
          subCategories: isCategoryCurrentlySelected
            ? {}
            : prevSelected[categoryId]?.subCategories || {},
        },
      };

      const isAnyCategorySelected = Object.values(updatedCategories).some(
        (category) =>
          category.selected || Object.keys(category.subCategories).length > 0
      );

      if (SurveyType !== "Organisation") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [questionId]: isAnyCategorySelected
            ? ""
            : "Please select at least one category.",
        }));
      }

      return updatedCategories;
    });
  };

  // const handleSubCategorySelection = (
  //   questionId,
  //   categoryId,
  //   subCategoryId
  // ) => {
  //   setSelectedCategories((prevSelected) => {
  //     const updatedSubCategories = {
  //       ...prevSelected[categoryId],
  //       subCategories: {
  //         ...prevSelected[categoryId]?.subCategories,
  //         [subCategoryId]:
  //           !prevSelected[categoryId]?.subCategories?.[subCategoryId],
  //       },
  //     };

  //     const isSubCategorySelected = Object.values(
  //       updatedSubCategories.subCategories
  //     ).some((isSelected) => isSelected);

  //     const updatedCategories = {
  //       ...prevSelected,
  //       [categoryId]: {
  //         ...updatedSubCategories,
  //       },
  //     };

  //     const isAnyCategorySelected = Object.values(updatedCategories).some(
  //       (category) => category.selected
  //     );

  //     let allCategoriesHaveSubCategory = true;
  //     if (isAnyCategorySelected) {
  //       allCategoriesHaveSubCategory = Object.values(updatedCategories).every(
  //         (category) =>
  //           !category.selected ||
  //           Object.values(category.subCategories || {}).some(
  //             (subSelected) => subSelected
  //           )
  //       );
  //     }

  //     setErrors((prevErrors) => ({
  //       ...prevErrors,
  //       [questionId]: !isAnyCategorySelected
  //         ? "Please select at least one category."
  //         : !allCategoriesHaveSubCategory
  //         ? "Please select at least one subcategory for each selected category."
  //         : "",
  //     }));

  //     return updatedCategories;
  //   });
  // };

  const handleSubCategorySelection = (
    questionId,
    categoryId,
    subCategoryId
  ) => {
    setSelectedCategories((prevSelected) => {
      const updatedSubCategories = {
        ...prevSelected[categoryId],
        subCategories: {
          ...prevSelected[categoryId]?.subCategories,
          [subCategoryId]:
            !prevSelected[categoryId]?.subCategories?.[subCategoryId],
        },
      };

      // const isSubCategorySelected = Object.values(
      //   updatedSubCategories.subCategories
      // ).some((isSelected) => isSelected);

      const updatedCategories = {
        ...prevSelected,
        [categoryId]: {
          ...updatedSubCategories,
        },
      };

      const isAnyCategorySelected = Object.values(updatedCategories).some(
        (category) =>
          category.selected || Object.keys(category.subCategories).length > 0
      );

      let allCategoriesHaveSubCategory = true;
      if (isAnyCategorySelected) {
        allCategoriesHaveSubCategory = Object.values(updatedCategories).every(
          (category) =>
            !category.selected ||
            Object.values(category.subCategories || {}).some(
              (subSelected) => subSelected
            )
        );
      }

      setErrors((prevErrors) => ({
        ...prevErrors,
        [questionId]: !isAnyCategorySelected
          ? "Please select at least one category."
          : !allCategoriesHaveSubCategory
          ? "Please select at least one subcategory for each selected category."
          : "",
      }));

      return updatedCategories;
    });
  };

  const shouldShowInput = (
    selectedCategories,
    categoryId,
    subCategoryId,
    questionId,
    categoryName,
    categoryValid
  ) => {
    if (
      // categoryName === "Other research areas" &&
      categoryValid === "True" &&
      questionId &&
      categoryId &&
      subCategoryId
    ) {
      // if (questionId === 69 && categoryId === 70 && subCategoryId === 232) {
      if (!selectedCategories[questionId]) {
        return false;
      }

      const category = selectedCategories[questionId].find(
        (category) => category[categoryId]
      );

      if (category && category[categoryId].includes(subCategoryId)) {
        localStorage.setItem("isChecked", true);

        return true;
      } else {
        // localStorage.setItem("isChecked", false);
      }
    }
    localStorage.setItem("isChecked", false);
    return false;
  };

  const handleCheckboxNewSelection = (
    categoryId,
    subCategoryId,
    questionId
  ) => {
    setSelectedCategories((prevSelectedCategories) => {
      const updatedCategories = { ...prevSelectedCategories };

      if (!updatedCategories[questionId]) {
        updatedCategories[questionId] = [];
      } else {
      }

      let category = updatedCategories[questionId].find(
        (cat) => Object.keys(cat)[0] === categoryId.toString()
      );

      if (!category) {
        category = { [categoryId]: [] };
        updatedCategories[questionId].push(category);
      }

      if (category[categoryId].includes(subCategoryId)) {
        category[categoryId] = category[categoryId].filter(
          (id) => id !== subCategoryId
        );

        if (category[categoryId].length === 0) {
          updatedCategories[questionId] = updatedCategories[questionId].filter(
            (cat) => {
              return Object.keys(cat)[0] !== categoryId.toString();
            }
          );
        }
      } else {
        category[categoryId].push(subCategoryId);
      }

      if (updatedCategories[questionId]) {
        updatedCategories[questionId] = updatedCategories[questionId].map(
          (cat) => {
            const key = Object.keys(cat)[0];

            if (key === categoryId.toString()) {
              cat[key] = cat[key].filter((subCat) => {
                return !subCat[subCategoryId];
              });
            }
            return cat;
          }
        );

        // Remove categories with empty subcategories
        updatedCategories[questionId] = updatedCategories[questionId].filter(
          (cat) => {
            const key = Object.keys(cat)[0];
            return cat[key].length > 0;
          }
        );
      }

      // Final cleanup: If no categories remain, delete the questionId key
      if (
        updatedCategories[questionId] &&
        updatedCategories[questionId].length === 0
      ) {
        delete updatedCategories[questionId];
      }

      // const category69 = updatedCategories[69];

      // const isInvalidValue =
      //   category69 &&
      //   category69.some((entry) => {
      //     if (entry[70]) {
      //       return (
      //         (entry[70][0] === 232 && entry[70].length === 1) ||
      //         (entry[70][0] === 232 && entry[70][1] && entry[70][1][232] === "")
      //       );
      //     }
      //     return false;
      //   });

      if (isChecked === "true") {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [questionId]: ["This input field is required."],
        }));
      } else {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[questionId];
          return updatedErrors;
        });
      }

      // Check if there are no selected subcategories for the question
      const hasSubcategories = updatedCategories[questionId]?.some((entry) =>
        Object.values(entry)?.some((subcategories) => subcategories.length > 0)
      );

      setErrors((prevErrors) => {
        const updatedErrors = { ...prevErrors };
        if (!hasSubcategories) {
          updatedErrors[questionId] = [
            "Please select at least one subcategory.",
          ];
        } else {
          delete updatedErrors[questionId];
        }
        return updatedErrors;
      });

      return updatedCategories;
    });
  };

  const handleCustomOptionChange = (categoryId, option, questionId, item) => {
    setSelectedCategories((prevState) => {
      const updatedState = { ...prevState };

      if (!updatedState[questionId]) {
        updatedState[questionId] = {};
      }

      if (!updatedState[questionId][categoryId]) {
        updatedState[questionId][categoryId] = [];
      }

      const subcategoryArray = updatedState[questionId][categoryId];

      if (subcategoryArray.includes(option)) {
        updatedState[questionId][categoryId] = subcategoryArray.filter(
          (id) => id !== option
        );
      } else {
        updatedState[questionId][categoryId].push(option);
      }

      const newErrors = {};
      const selectedCategoriesForQuestion = updatedState[questionId] || {};

      if (item.eType === "Custom") {
        let hasError = false;

        item.categories.forEach((category) => {
          const categoryId = category.iCategoryId;
          const selectedOptions =
            selectedCategoriesForQuestion[categoryId] || [];

          if (selectedOptions.length === 0) {
            hasError = true;
          }
        });

        if (hasError) {
          newErrors[questionId] = [
            "Please select at least one option for each category.",
          ];
        } else {
          delete newErrors[questionId];
        }

        setErrors((prevErrors) => {
          let updatedErrors;
          if (!hasError) {
            const { [questionId]: removedError, ...remainingErrors } =
              prevErrors;
            updatedErrors = { ...remainingErrors, ...newErrors };
          } else {
            updatedErrors = { ...prevErrors, ...newErrors };
          }

          return updatedErrors;
        });
      }

      return updatedState;
    });
  };

  const validateSelection = () => {
    const newErrors = {};

    surveyQuestionData?.data?.Data?.forEach((item) => {
      if (item.eType === "Single Select") {
        if (!selectedCategories[item.iQuestionId]) {
          newErrors[item.iQuestionId] = ["please select an option."];
        }
      } else if (item.eType === "Multi Select") {
        if (
          !selectedCategories[item.iQuestionId] ||
          selectedCategories[item.iQuestionId].length === 0
        ) {
          newErrors[item.iQuestionId] = ["Please select at least one option."];
        }
      } else if (item.eType === "Dropdown") {
        if (
          !inputValues[item.iQuestionId] ||
          inputValues[item.iQuestionId] === ""
        ) {
          newErrors[item.iQuestionId] = ["Please select an option."];
        }
      } else if (item.eType === "Text" || item.eType === "Description") {
        if (item.vQuestion.includes("Other(Mandatory")) {
          if (
            // OptionValue1 &&
            localStorage.getItem(
              `Other_${item.iParentQuestionId}_${SurveyType}`
            ) &&
            item.iQuestionId &&
            (!inputValues[item.iQuestionId] ||
              inputValues[item.iQuestionId].trim() === "")
          ) {
            newErrors[item.iQuestionId] = [
              "You selected 'Other'. Please fill in the required field.",
            ];
          }

          // if (
          //   OptionValue3 &&
          //   item.iQuestionId === 57 &&
          //   (!inputValues[item.iQuestionId] ||
          //     inputValues[item.iQuestionId].trim() === "")
          // ) {
          //   newErrors[item.iQuestionId] = [
          //     "You selected 'Other'. Please fill in the required field.",
          //   ];
          // }
        } else {
          if (
            !inputValues[item.iQuestionId] ||
            inputValues[item.iQuestionId].trim() === ""
          ) {
            newErrors[item.iQuestionId] = ["This field is required."];
          }
        }
      } else if (item.eType === "Custom") {
        const selectedCategoriesForQuestion =
          selectedCategories[item.iQuestionId] || {};

        let hasError = false;
        let errorMessage =
          "Please select at least one option for each category";

        item.categories.forEach((category) => {
          const categoryId = category.iCategoryId;

          const categoryOptions =
            selectedCategoriesForQuestion[categoryId] || [];

          if (categoryOptions.length === 0) {
            hasError = true;
          }
        });

        if (hasError) {
          if (!newErrors[item.iQuestionId]) {
            newErrors[item.iQuestionId] = [];
          }

          if (!newErrors[item.iQuestionId].includes(errorMessage)) {
            newErrors[item.iQuestionId].push(errorMessage);
          }
        } else {
          delete newErrors[item.iQuestionId];
        }
      } else if (item.eType === "Range") {
        if (
          !inputValues[item.iQuestionId] ||
          inputValues[item.iQuestionId] === "" ||
          inputValues[item.iQuestionId] === 0
        ) {
          newErrors[item.iQuestionId] = [
            "Please select a value for this range.",
          ];
        }
      } else if (item.eType === "Table" && SurveyType === "Academic") {
        let isAnyCategorySelected = false;
        let allCategoriesHaveSubCategory = true;

        item.categories.forEach((category) => {
          const categoryData = selectedCategories[category?.iCategoryId];
          const isCategorySelected = categoryData?.selected;
          isAnyCategorySelected = isAnyCategorySelected || isCategorySelected;

          if (isCategorySelected) {
            const subCategories = categoryData.subCategories || {};
            const hasSelectedSubCategory = Object.values(subCategories).some(
              (isSelected) => isSelected
            );
            if (!hasSelectedSubCategory) {
              allCategoriesHaveSubCategory = false;
            }
          }
        });

        if (!isAnyCategorySelected && !newErrors[item.iQuestionId]) {
          newErrors[item.iQuestionId] = [
            "Please select at least one category.",
          ];
        } else if (!allCategoriesHaveSubCategory) {
          newErrors[item.iQuestionId] = [
            "Please select at least one subcategory for each selected category.",
          ];
        }
      } else if (item.eType === "Table" && SurveyType === "Organisation") {
        if (
          !selectedCategories[item.iQuestionId] ||
          selectedCategories[item.iQuestionId].length === 0
        ) {
          newErrors[item.iQuestionId] = [
            "Please select at least one subcategory.",
          ];
        }

        const CategoryValue = item.categories.find(
          (category) => category.isCategoryValid
        );

        const CategoryId = CategoryValue.iCategoryId;

        // const subcategoryValue = Number(
        //   selectedCategories[item.iQuestionId][0][70]
        // );

        const relatedQuestionCategories = selectedCategories[item.iQuestionId];

        const isEmptyValue =
          relatedQuestionCategories &&
          relatedQuestionCategories.some((entry) => {
            if (entry[CategoryId]) {
              if (
                entry[[CategoryId]][0] === 232 &&
                (entry[[CategoryId]][1]?.[232] === "" ||
                  !entry[[CategoryId]][1])
              ) {
                return true;
              }
              return false;
            }
          });

        if (isEmptyValue) {
          newErrors[item.iQuestionId] = ["This input field is required."];
        }
      }
    });

    setErrors(newErrors);

    return { isValidData: Object.keys(newErrors).length === 0, newErrors };
  };

  const handleRadioSelection = (questionId, optionId, optionText) => {
    if (optionText === "No") {
      // navigate("/organisation/questions/page-6");
      localStorage.setItem(`OptionText_${SurveyType}`, "No");
    } else {
      localStorage.setItem(`OptionText_${SurveyType}`, "");
    }

    setSelectedCategories((prevSelectedCategories) => ({
      ...prevSelectedCategories,
      [questionId]: optionId,
    }));

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[questionId];
      return newErrors;
    });
  };

  const handleCheckboxSelection = (
    questionId,
    optionId,
    value,
    otherQuestionId
  ) => {
    setSelectedCategories((prevSelectedCategories) => {
      const currentSelections = Array.isArray(
        prevSelectedCategories[questionId]
      )
        ? prevSelectedCategories[questionId]
        : [];

      let updatedSelections;
      if (currentSelections.includes(optionId)) {
        updatedSelections = currentSelections.filter((id) => id !== optionId);
      } else {
        updatedSelections = [...currentSelections, optionId];
      }

      if (value === "Other") {
        if (updatedSelections.includes(optionId)) {
          localStorage.setItem(`Other_${questionId}_${SurveyType}`, "Other3");

          setErrors((prevErrors) => ({
            ...prevErrors,
            [otherQuestionId]:
              "You selected 'Other'. Please fill in the required field.",
          }));
        } else {
          localStorage.removeItem(`Other_${questionId}_${SurveyType}`);
          setErrors((prevErrors) => {
            const newErrors = { ...prevErrors };
            delete newErrors[otherQuestionId];
            return newErrors;
          });
        }
      }

      if (updatedSelections.length === 0) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [questionId]: "Please select at least one option.",
        }));
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[questionId];
          return newErrors;
        });
      }

      return {
        ...prevSelectedCategories,
        [questionId]: updatedSelections,
      };
    });
  };

  const renderErrorMessages = (questionId, questionType) => {
    return (
      errors[questionId] && (
        <div ref={(el) => (questionRefs.current[questionId] = el)}>
          {/* Check if errors[questionId] is an array */}
          {Array.isArray(errors[questionId]) ? (
            errors[questionId].map((error, errorIndex) => (
              <div
                key={errorIndex}
                style={{ color: "red", marginBottom: "10px" }}
                className="error-message"
              >
                {error}
              </div>
            ))
          ) : (
            // If it's a single error message (not an array)
            <div
              style={{ color: "red", marginBottom: "10px" }}
              className="error-message"
            >
              {errors[questionId]}
            </div>
          )}
        </div>
      )
    );
  };

  // for eType = Table Question Working code//

  const renderTableQuestion = (item, index) => (
    <>
      {SurveyType === "Organisation" ? (
        <div>
          <h2 className="qus-title">{`${index + 1}. ${item.vQuestion}`}</h2>
          {item?.categories?.map((section) => (
            <div className="section" key={section.iCategoryId}>
              <h3>{section?.vCategory}</h3>
              {section?.subCategories?.map((subItem) => (
                <div
                  className="category-list border-0 pt-0"
                  key={subItem?.iSubCategoryId}
                >
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id={`specific-${subItem?.iSubCategoryId}`}
                      className="form-check-input"
                      onChange={() =>
                        handleCheckboxNewSelection(
                          section?.iCategoryId,
                          subItem?.iSubCategoryId,
                          item.iQuestionId
                        )
                      }
                      checked={
                        selectedCategories[item.iQuestionId] &&
                        selectedCategories[item.iQuestionId].some(
                          (category) =>
                            category[section?.iCategoryId] &&
                            category[section?.iCategoryId].includes(
                              subItem?.iSubCategoryId
                            )
                        )
                      }
                    />
                    <label
                      htmlFor={`specific-${subItem?.iSubCategoryId}`}
                      className="category-title"
                    >
                      {subItem?.vSubCategory}
                    </label>
                  </div>

                  {shouldShowInput(
                    selectedCategories,
                    section.iCategoryId,
                    subItem.iSubCategoryId,
                    item.iQuestionId,
                    section?.vCategory,
                    section.isCategoryValid
                  ) && (
                    <input
                      type="text"
                      placeholder="Type your Answer..."
                      className="form-control mt-2"
                      onChange={(e) =>
                        handleOtherInputChange(
                          e.target.value,
                          item.iQuestionId,
                          section.iCategoryId,
                          subItem.iSubCategoryId
                        )
                      }
                      value={
                        getInputValueFromLocalStorage(
                          item.iQuestionId,
                          section.iCategoryId,
                          subItem.iSubCategoryId
                        ) || ""
                      }
                    />
                  )}

                  {/* {selectedCategories && selectedCategories[69]
                    ? selectedCategories[69]
                        .map((category) => {
                          if (category.categoryId === 70) {
                            if (Array.isArray(category.subCategories)) {
                              return category.subCategories.map((sub) => {
                                if (
                                  section.iCategoryId === 70 &&
                                  category.categoryId === 70 &&
                                  sub === 232
                                ) {
                                  return (
                                    <div>
                                      <input
                                        key={sub}
                                        type="text"
                                        className="form-control mt-2"
                                        placeholder="Type Your Answer Here..."
                                        onChange={(e) =>
                                          handleOtherInputChange(
                                            e.target.value,
                                            69,
                                            70,
                                            232
                                          )
                                        }
                                      />
                                    </div>
                                  );
                                }
                                return null;
                              });
                            } else {
                              return null;
                            }
                          } else {
                            return null;
                          }
                        })
                        .flat()
                    : false} */}
                </div>
              ))}
            </div>
          ))}
        </div>
      ) : (
        <>
          <>
            <div>
              <h2 className="qus-title">{`${index + 1}. ${item.vQuestion}`}</h2>
              {item.categories.map((category) => (
                <div
                  className="category-list border-0 pt-0"
                  key={category?.iCategoryId}
                >
                  <div className="form-check">
                    <input
                      type="checkbox"
                      id={`specific-${category?.iCategoryId}`}
                      className="form-check-input"
                      onChange={() =>
                        handleCategorySelection(
                          item.iQuestionId,
                          category?.iCategoryId
                        )
                      }
                      checked={
                        selectedCategories[category?.iCategoryId]?.selected ||
                        false
                      }
                    />
                    <label
                      htmlFor={`specific-${category?.iCategoryId}`}
                      className="category-title"
                    >
                      {category?.vCategory}
                    </label>
                  </div>
                  {selectedCategories[category?.iCategoryId]?.selected && (
                    <div className="sub-category-list">
                      <div className="row g-3">
                        {category?.subCategories.map((subCategory) => (
                          <div
                            className="col-md-6 col-lg-4"
                            key={subCategory?.iSubCategoryId}
                          >
                            <div className="form-check">
                              <input
                                type="checkbox"
                                id={`sub-${category?.iCategoryId}-${subCategory?.iSubCategoryId}`}
                                className="form-check-input"
                                onChange={() =>
                                  handleSubCategorySelection(
                                    item?.iQuestionId,
                                    category?.iCategoryId,
                                    subCategory?.iSubCategoryId
                                  )
                                }
                                checked={
                                  selectedCategories[category?.iCategoryId]
                                    ?.subCategories?.[
                                    subCategory?.iSubCategoryId
                                  ] || false
                                }
                              />
                              <label
                                htmlFor={`sub-${category?.iCategoryId}-${subCategory?.iSubCategoryId}`}
                              >
                                {subCategory?.vSubCategory}
                              </label>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        </>
      )}
    </>
  );

  const renderCustomQuestion = (item, index) => {
    const optionsArray = JSON.parse(item?.vOption);

    return (
      <>
        <div className="main-category">
          <h2 className="qus-title">{`${index + 1}. ${item.vQuestion}`}</h2>

          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  {optionsArray.map((option, idx) => (
                    <th key={idx}>{option}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {item?.categories.map((category, categoryIndex) => (
                  <tr key={categoryIndex}>
                    <td>{category.vCategory}</td>
                    {optionsArray.map((option, optionIndex) => (
                      <td key={optionIndex}>
                        <div className="form-check p-0">
                          <input
                            type="checkbox"
                            id={`checkbox-${category.iCategoryId}-${optionIndex}`}
                            className="form-check-input"
                            name={`question-${category.iCategoryId}-${optionIndex}`}
                            onChange={() =>
                              handleCustomOptionChange(
                                category.iCategoryId,
                                option,
                                item.iQuestionId,
                                item
                              )
                            }
                            checked={
                              selectedCategories[item.iQuestionId] &&
                              selectedCategories[item.iQuestionId][
                                category.iCategoryId
                              ] &&
                              selectedCategories[item.iQuestionId][
                                category.iCategoryId
                              ].includes(option)
                            }
                          />
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  };

  // for eType = Multi Select Question//
  const renderMultiSelectQuestion = (item, index) => (
    <>
      <h2 className="qus-title"> {`${index + 1}. ${item.vQuestion}`}</h2>
      {item.options.map((option) => (
        <div className="category-list border-0 pt-0" key={option.iOptionId}>
          <div className="form-check">
            <input
              type="checkbox"
              id={`specific-${option.iOptionId}`}
              className="form-check-input"
              onChange={() =>
                handleCheckboxSelection(
                  item.iQuestionId,
                  option.iOptionId,
                  option.vOption,
                  item.iMainQuestionId
                )
              }
              checked={
                (Array.isArray(selectedCategories[item.iQuestionId]) &&
                  selectedCategories[item.iQuestionId]?.includes(
                    option.iOptionId
                  )) ||
                false
              }
            />
            <label
              htmlFor={`specific-${option.iOptionId}`}
              className="category-title"
            >
              {option.vOption}
            </label>
          </div>
        </div>
      ))}
    </>
  );



  // for eType = single select //
  const renderSingleSelectQuestion = (item, index) => (
    <>
      <h2 className="qus-title"> {`${index + 1}. ${item.vQuestion}`}</h2>
      {item.options.map((option) => (
        <div className="category-list border-0 pt-0" key={option.iOptionId}>
          <div className="form-check">
            <input
              type="radio"
              id={`specific-${option.iOptionId}`}
              name={`question-${item.iQuestionId}`} // Group radio buttons by question
              onChange={() =>
                handleRadioSelection(
                  item.iQuestionId,
                  option.iOptionId,
                  option.vOption
                )
              }
              checked={
                selectedCategories[item.iQuestionId] === option.iOptionId
              }
              className="form-check-input radio-check"
            />
            <label
              htmlFor={`specific-${option.iOptionId}`}
              className="category-title"
            >
              {option.vOption}
            </label>
          </div>
        </div>
      ))}
    </>
  );

  const renderDropdownQuestion = (item, index) => {
    const selectedValue = selectedCategories[item.iQuestionId] || "";

    return (
      <div key={item.iQuestionId}>
        <h2 className="qus-title"> {`${index + 1}. ${item.vQuestion}`}</h2>
        <div className="col-md-12">
          <div className="other-select-wrp">
            <div className="select-dropdown">
              <select
                id={`dropdown-${item.iQuestionId}`}
                name={`dropdown-${item.iQuestionId}`}
                value={selectedValue}
                onChange={(e) =>
                  handleDropdownChange(
                    item.iQuestionId,
                    e.target.value,
                    item.iMainQuestionId,
                    item.options
                  )
                }
              >
                <option value="">Select Any Option</option>
                {item.options.map((option) => (
                  <option key={option.iOptionId} value={option.iOptionId}>
                    {option.vOption}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // for eType = Description  Question//
  const renderDescriptionQuestion = (item, index) => (
    <>
      <h2 className="qus-title">
        {`${index + 1}. ${item.vQuestion}`}.{item.vQuestion}
      </h2>
      <div className="col-md-12">
        <div className="input-box">
          <textarea
            type="text"
            className="form-control"
            rows={4}
            cols={50}
            placeholder="Type Answer Here..."
            value={inputValues[item.iQuestionId] || ""}
            onChange={(e) =>
              handleInputChange(
                item.iQuestionId,
                e.target.value,
                item.eType,
                item.vQuestion
              )
            }
          ></textarea>
        </div>
      </div>
    </>
  );

  // for eType = Range Question//
  const renderRangeQuestion = (item, index) => (
    <>
      <h2 className="qus-title">
        {`${index + 1}. ${item.vQuestion}`}.{item.vQuestion}
      </h2>
      <div>
        <input
          type="range"
          id="volume"
          name="volume"
          min="0"
          max="12"
          value={inputValues[item.iQuestionId] || 0}
          onChange={(e) =>
            handleInputChange(item.iQuestionId, e.target.value, item.eType)
          }
        />
      </div>
    </>
  );

  // for eType = Text Question//
  const renderTextInputQuestion = (item, index) => {
    // const errorMessage = errors.inputTextField;
    return (
      <>
        <h2 className="qus-title">{`${index + 1}. ${item.vQuestion}`}</h2>
        <div className="col-md-12">
          <div className="input-box">
            <input
              type="text"
              className="form-control"
              placeholder="Type Answer Here..."
              value={inputValues[item.iQuestionId] || ""}
              onChange={(e) =>
                handleInputChange(
                  item.iQuestionId,
                  e.target.value,
                  item.eType,
                  item
                )
              }
            />
          </div>
        </div>
      </>
    );
  };

  // Fetch survey questions on page load or when pageNumber changes
  React.useEffect(() => {
    const cleanedUniqueCode = vUniqueCode && vUniqueCode.replace(/^"|"$/g, "");
    dispatch(
      getSurveyQuestionList({
        vUniqueCode: cleanedUniqueCode,
        iPageNo: pageNumber,
        // vPage: 1,
        // limit:2
      })
    );
  }, [dispatch, vUniqueCode, pageNumber]);

  const handleEmailChange = (event) => {
    const emailValue = event.target.value.trim();
    setEmail(emailValue);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailValue)) {
      setEmailError("Invalid Email format.");
    } else {
      setEmailError("");
      localStorage.setItem(`email_${SurveyType}`, emailValue);
    }
  };

  // React.useEffect(() => {
  //   // Only scroll if there is a first error and it is set
  //   const setTimeout(() => {

  //   const firstErrorId = Object.keys(errors)[0];
  //   if (firstErrorId && questionRefs.current[firstErrorId]) {
  //     questionRefs.current[firstErrorId].scrollIntoView({
  //       behavior: "smooth",
  //       block: "center",
  //     });
  //   }

  // }, 5000);
  // return () => clearTimeout(timer);

  // }, [errors]);

  React.useEffect(() => {
    if (hasScrolled) return;

    const firstErrorId = Object.keys(errors)[0];
    if (firstErrorId && questionRefs.current[firstErrorId]) {
      questionRefs.current[firstErrorId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
      setHasScrolled(true);
    }
  }, [errors, questionRefs, hasScrolled]);

  const handleNextQuestion = async () => {
    setHasScrolled(false);

    const { isValidData, newErrors } = validateSelection();

    if (!isValidData) {
      const errorKeys = Object.keys(newErrors);
      // .sort((a, b) => {
      //   const aPos = questionRefs.current[a]?.offsetTop || 0;
      //   const bPos = questionRefs.current[b]?.offsetTop || 0;
      //   return aPos - bPos;
      // });

      const firstErrorId = errorKeys[0];

      if (firstErrorId && questionRefs.current[firstErrorId]) {
        setTimeout(() => {
          questionRefs.current[firstErrorId].scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
          setHasScrolled(true);
        }, 0);
      }
      return;
    }

    let iOptionId = [];

    surveyQuestionData?.data?.Data?.forEach((question) => {
      if (question.eType === "Table") {
        let questionOptionArray = [];

        const mergedCategories = {};

        question?.categories?.forEach((category) => {
          const categoryId = category?.iCategoryId;

          if (!mergedCategories[categoryId]) {
            mergedCategories[categoryId] = new Set();
          }

          category?.subCategories?.forEach((subCategory) => {
            const subCategoryId = subCategory?.iSubCategoryId;
            if (subCategoryId) {
              mergedCategories[categoryId].add(subCategoryId);
            }
          });
        });

        if (
          SurveyType === "Organisation" &&
          selectedCategories[question.iQuestionId]
        ) {
          const questionCategories = selectedCategories[question.iQuestionId];

          questionCategories.forEach((categoryObj) => {
            const categoryId = Object.keys(categoryObj)[0];
            const subCategoryArray = categoryObj[categoryId];

            if (mergedCategories[categoryId]) {
              const categoryData = { [categoryId]: [] };

              let hasAnswer = false;

              subCategoryArray.forEach((subCategory) => {
                if (typeof subCategory === "object") {
                  const subCategoryId = Object.keys(subCategory)[0];

                  const answer = subCategory[subCategoryId];

                  if (answer && isChecked === "true") {
                    categoryData[categoryId].push({ [subCategoryId]: answer });
                    hasAnswer = true;
                  }
                } else if (!hasAnswer) {
                  categoryData[categoryId].push(subCategory);
                }
              });

              if (categoryData[categoryId].length > 0) {
                questionOptionArray.push(categoryData);
              }
            }
          });
        } else {
          question?.categories?.forEach((category) => {
            if (selectedCategories[category?.iCategoryId]?.selected) {
              const selectedSubCategoryIds = Object.keys(
                selectedCategories[category?.iCategoryId]?.subCategories || {}
              ).filter(
                (subCatId) =>
                  selectedCategories[category?.iCategoryId].subCategories[
                    subCatId
                  ]
              );

              if (selectedSubCategoryIds.length > 0) {
                questionOptionArray.push({
                  [category?.iCategoryId]: selectedSubCategoryIds.map(Number),
                });
              }
            }
          });
        }

        if (questionOptionArray.length > 0) {
          iOptionId.push(questionOptionArray);
        }
      } else if (
        question.eType === "Text" ||
        question.eType === "Description"
      ) {
        const textAnswer = inputValues[question.iQuestionId]?.trim();
        if (textAnswer) {
          iOptionId.push([textAnswer]);
        } else {
          iOptionId.push([""]);
        }
      } else if (question.eType === "Single Select") {
        const selectedValue = selectedCategories[question.iQuestionId] || [];

        if (selectedValue) {
          iOptionId.push([selectedValue]);
        } else {
          iOptionId.push([]);
        }
      } else if (question.eType === "Multi Select") {
        const selectedValues = selectedCategories[question.iQuestionId] || [];

        if (selectedValues.length > 0) {
          iOptionId.push(selectedValues.map(Number));
        } else {
          iOptionId.push([]);
        }
      } else if (question.eType === "Range") {
        const rangeValue = inputValues[question.iQuestionId];
        if (rangeValue) {
          iOptionId.push([rangeValue]);
        } else {
          iOptionId.push([null]);
        }
      } else if (question.eType === "Dropdown") {
        const selectedValue = selectedCategories[question.iQuestionId];
        if (selectedValue) {
          iOptionId.push([selectedValue]);
        } else {
          iOptionId.push([""]);
        }
      } else if (question.eType === "Custom") {
        let customOptionArray = [];

        question?.categories?.forEach((category) => {
          const categoryId = category?.iCategoryId;

          if (
            selectedCategories[question.iQuestionId] &&
            selectedCategories[question.iQuestionId][categoryId]
          ) {
            const subCategories =
              selectedCategories[question.iQuestionId][categoryId];

            const selectedSubCategoryIds = Array.isArray(subCategories)
              ? subCategories
              : [subCategories];

            if (selectedSubCategoryIds.length > 0) {
              customOptionArray.push({
                [categoryId]: selectedSubCategoryIds,
              });
            }
          }
        });

        if (customOptionArray.length > 0) {
          iOptionId.push(customOptionArray);
        }
      } else if (question.eType === "Table" && SurveyType === "Organisation") {
        let questionOptionArray = [];

        question?.categories?.forEach((category) => {
          const categoryId = category?.iCategoryId;

          if (selectedCategories[categoryId]) {
            const subCategories = selectedCategories[categoryId];

            // Check if subCategories is an array or a single value
            const selectedSubCategoryIds = Array.isArray(subCategories)
              ? subCategories
              : [subCategories]; // Wrap single values in an array

            if (selectedSubCategoryIds.length > 0) {
              questionOptionArray.push({
                [categoryId]: selectedSubCategoryIds.map(Number),
              });
            }
          }
        });

        if (questionOptionArray.length > 0) {
          iOptionId.push(questionOptionArray);
        }
      }
    });

    // Proceed only if the form is valid
    if (isValidData) {
      const payload = {
        vUniqueCode:
          participantUniqueCode || userUniqueCode || participantvUniqueCodeNew,
        iQuestionId: surveyQuestionData?.data?.Data.map((question) => ({
          [question.iQuestionId]: question.eType,
        })),
        iOptionId: iOptionId,
        iSurveyId: participantSurveyId || SurveyId,
        iPageNo: pageNumber,
        ...(optionText && { vFlag: optionText }),
      };

      try {
        setButtonLoading(true);
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/question/answer`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          }
        );

        const responseData = await response.json();
        setButtonLoading(false);
        if (optionText === "No") {
          if (responseData && responseData.status === 200) {
            if (responseData.data?.survey_status === "Completed") {
              navigate("/user-details");
              // clearLocalStorage();
              clearLocalStorage(Object.keys(selectedCategories));
            }
          }
        } else {
          if (responseData && responseData.status === 200) {
            if (responseData.data?.survey_status === "Completed") {
              navigate("/survey/thank-you");
              // clearLocalStorage();
              clearLocalStorage(Object.keys(selectedCategories));
            } else {
              setPageNumber(responseData.data?.NextPageNo);
              window.scrollTo(0, 0);
            }
          }
        }
      } catch (error) {
        setButtonLoading(false);
        // console.error(error); // Handle error
      }
    } else {
      // console.log("error")
    }
  };

  const handleBackQuestion = () => {
    if (pageNumber === 1 && SurveyType === "Organisation") {
      navigate("/project-details", {
        state: { vUniqueCode, SurveyId, participantvUniqueCodeNew, SurveyType },
      });
    }
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber - 1);
      window.scrollTo(0, 0);
    } else if (SurveyType === "Academic") {
      navigate("/general-info", {
        state: { vUniqueCode, SurveyId, participantvUniqueCodeNew, SurveyType },
      });
    }
  };

  const handleInputChange = (questionId, value, questionType, question) => {
    // Update the selectedCategories only for specific types like Text/Description/Range

    if (
      questionType === "Text" ||
      questionType === "Description" ||
      questionType === "Range"
    ) {
      if (value.length > 0) {
        setErrors((prevErrors) => {
          const updatedErrors = { ...prevErrors };
          delete updatedErrors[questionId];
          return updatedErrors;
        });
      }
      setSelectedCategories((prevSelectedCategories) => {
        const updatedCategories = {
          ...prevSelectedCategories,
          [questionId]: value,
        };

        // Store updated selectedCategories in localStorage
        localStorage.setItem(
          `selectedCategories_${SurveyType}`,
          JSON.stringify(updatedCategories)
        );
        return updatedCategories;
      });
    }

    // Update inputValues for other types
    setInputValues((prevInputValues) => {
      const updatedInputValues = {
        ...prevInputValues,
        [questionId]: value,
      };

      // Store updated inputValues in localStorage
      localStorage.setItem(
        `inputValues_${SurveyType}`,
        JSON.stringify(updatedInputValues)
      );

      return updatedInputValues;
    });

    // Handle errors if needed

    const checkOtherQuestion =
      question && question.vQuestion.includes("Other(Mandatory)");

    if (value === "" && !checkOtherQuestion) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [questionId]: "This field is required.",
      }));
    } else if (
      questionType === "Text" &&
      question &&
      question.vQuestion &&
      question.vQuestion.includes("email address")
    ) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [questionId]: "Invalid Email format.",
        }));
      } else {
        setErrors((prevErrors) => {
          const { [questionId]: _, ...restErrors } = prevErrors;
          return restErrors;
        });
      }
    } else {
      setErrors((prevErrors) => {
        const { [questionId]: _, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  const handleDropdownChange = (
    questionId,
    value,
    otherQuestionId,
    options
  ) => {
    const selectedOption = options.find(
      (option) => option.iOptionId === parseInt(value)
    );

    const isOtherOption = selectedOption?.vOtherOption === "True";

    if (isOtherOption) {
      localStorage.setItem(`Other_${questionId}_${SurveyType}`, "Other");
    } else {
      localStorage.removeItem(`Other_${questionId}_${SurveyType}`);
    }

    // Update selectedCategories
    setSelectedCategories((prevSelectedCategories) => {
      const updatedCategories = {
        ...prevSelectedCategories,
        [questionId]: value,
      };

      localStorage.setItem(
        `selectedCategories_${SurveyType}`,
        JSON.stringify(updatedCategories)
      );

      if (questionId && isOtherOption) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [otherQuestionId]:
            "You selected 'Other'. Please fill in the required field.",
        }));
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };

          if (questionId && newErrors[otherQuestionId]) {
            delete newErrors[otherQuestionId];
          }

          if (questionId && newErrors[otherQuestionId]) {
            delete newErrors[otherQuestionId];
          }

          return newErrors;
        });
      }

      return updatedCategories;
    });

    // Update inputValues
    setInputValues((prevInputValues) => {
      const updatedInputValues = {
        ...prevInputValues,
        [questionId]: value,
      };
      // Store updated inputValues in localStorage
      localStorage.setItem(
        `inputValues_${SurveyType}`,
        JSON.stringify(updatedInputValues)
      );
      return updatedInputValues;
    });

    //valiadation for dropdown values //
    if (value === "") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [questionId]: "Please select an option.",
      }));
    } else {
      setErrors((prevErrors) => {
        const { [questionId]: _, ...restErrors } = prevErrors;
        return restErrors;
      });
    }
  };

  React.useEffect(() => {
    const savedSelectedCategories = localStorage.getItem(
      `selectedCategories_${SurveyType}`
    );
    const savedInputValues = localStorage.getItem(`inputValues_${SurveyType}`);

    if (savedSelectedCategories) {
      setSelectedCategories(JSON.parse(savedSelectedCategories));
    }

    if (savedInputValues) {
      setInputValues(JSON.parse(savedInputValues));
    }
  }, [SurveyId, SurveyType]);

  // Helper function to clear local storage
  // const clearLocalStorage = () => {
  const clearLocalStorage = (questionIds = []) => {
    localStorage.removeItem(`pageNumber_${SurveyType}`);
    localStorage.removeItem(`selectedCategories_${SurveyType}`);
    localStorage.removeItem(`education_${SurveyType}`);
    localStorage.removeItem(`researchOption_${SurveyType}`);
    localStorage.removeItem(`Other_${SurveyType}`);
    localStorage.removeItem(`agree_${SurveyType}`);
    localStorage.removeItem(`role_${SurveyType}`);
    localStorage.removeItem(`disagree_${SurveyType}`);
    localStorage.removeItem("participantvUniqueCodeNew");
    localStorage.removeItem("participantUniqueCode");
    localStorage.removeItem(`email_${SurveyType}`);
    localStorage.removeItem(`inputValues_${SurveyType}`);
    // localStorage.removeItem(`Other1_${SurveyType}`);
    // localStorage.removeItem(`Other2_${SurveyType}`);
    localStorage.removeItem(`Other3_${SurveyType}`);
    localStorage.removeItem("isChecked");
    localStorage.removeItem(`OptionText_${SurveyType}`);

    // Loop through dynamic questionIds and clear related "Other_*" keys
    questionIds.forEach((questionId) => {
      localStorage.removeItem(`Other_${questionId}_${SurveyType}`);
    });
  };

  return (
    <>
      <Header />
      <div className="questions-wrp survey-category survey-page">
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "50px",
              marginBottom: "50px",
            }}
          >
            <img
              src="/assets/gif/350.gif"
              className="loading-gif"
              alt="Loading..."
            />
          </div>
        ) : (
          <>
            {surveyQuestionData?.code === 200 && surveyQuestionData?.data ? (
              <div className="container">
                <h2
                  className="main-title"
                  style={{ fontWeight: "bold", color: "#1b2754" }}
                >
                  {" "}
                  {surveyQuestionData?.data?.vHeading}
                </h2>
                {/* {surveyQuestionData?.data?.Data?.map((item, index) => ( */}
                {surveyQuestionData?.data?.Data?.sort(
                  (a, b) => a.iQuestionId - b.iQuestionId
                ) // Sort questions by iQuestionId in ascending order
                  ?.map((item, index) => (
                    <>
                      <div
                        className={
                          SurveyType === "Organisation"
                            ? "main-category survey-que"
                            : "main-category"
                        }
                        key={index}
                      >
                        {renderQuestionContent(item, index)}
                        {renderErrorMessages(item?.iQuestionId, item?.eType)}
                      </div>
                    </>
                  ))}
                {pageNumber === 2 && SurveyType === "Academic" && (
                  <div>
                    <h3>
                      If you are interested in gaining access to updates on this
                      research, please enter your contact information below. We
                      will contact you concerning publication of the results:
                    </h3>
                    <div className="row">
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Email Address"
                            value={email}
                            onChange={handleEmailChange}
                          />
                          {emailError && (
                            <div
                              className="error-msg"
                              style={{
                                color: "red",
                                marginLeft: "5px",
                                fontSize: "14px",
                              }}
                            >
                              {emailError}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div>
                  {buttonLoading ? (
                    <button
                      className="next-page login-btn"
                      type="button"
                      disabled
                    >
                      <span
                        className="spinner-border spinner-border-sm me-1"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {pageNumber === TotalPage
                        ? "Submitting..."
                        : "Loading..."}
                    </button>
                  ) : (
                    <button
                      className="next-page login-btn"
                      onClick={handleNextQuestion}
                    >
                      {pageNumber === TotalPage ? "Submit" : "Next"}
                    </button>
                  )}
                  {pageNumber === TotalPage ? (
                    ""
                  ) : (
                    <button
                      className="next-page back-btn"
                      style={{ marginLeft: "20px" }}
                      onClick={handleBackQuestion}
                    >
                      Back
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="no-data"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px 0",
                  marginTop: "50px",
                }}
              >
                No Data Found
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default QuestionThree;
