import React from "react";
import Header from "../Layouts/Header";
import { useLocation, useNavigate } from "react-router-dom";
import { getLabelList } from "../../store/Action/PageAction";
import { useDispatch, useSelector } from "react-redux";

const ThankYou = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const vUniqueCode = localStorage.getItem("vUniqueCode");
  const navigate = useNavigate();

  const { labelListData } = useSelector((state) => state.labelList);

  const thankYouPath = location.pathname.split("/").pop();

  const handleContinueClick = () => {
    if (vUniqueCode) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  };

  React.useEffect(() => {
    localStorage.removeItem("User_Email");
    localStorage.removeItem("User_Address");
  });

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: thankYouPath,
      })
    );
  }, [dispatch, thankYouPath]);

  const thankYouTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find((title) => title.vLabel === "LBL_THANKS_YOU_TITLE")
      ?.vText;

  const thankYouParticipantSheetTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_THANKS_YOU_INFO_SHEET_TITLE"
    )?.vText;

    const thankYouLastTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_THANKS_YOU_LAST_TITLE"
    )?.vText;

  const thankYouSubHeading =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_THANKS_YOU_SUB_TITLE"
    )?.vText;
  return (
    <>
      <Header />
      <div className="questions-wrp survey-page">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 mx-auto">
              <div className="survey-content text-center">
                <div className="thank-you-image">
                  <img
                    src="/assets/image/thank-you-img.png"
                    alt="thank-you-image"
                  />
                </div>
                <p>
                  {thankYouTitle}
                  <a href="https://login.microsoftonline.com/8acbc2c5-c8ed-42c7-8169-ba438a0dbe2f/oauth2/authorize?client%5Fid=00000003%2D0000%2D0ff1%2Dce00%2D000000000000&response%5Fmode=form%5Fpost&response%5Ftype=code%20id%5Ftoken&resource=00000003%2D0000%2D0ff1%2Dce00%2D000000000000&scope=openid&nonce=E17DF72FCD8761B45BF14BCDDBA7261309336B332FAA5F02%2DF0B01A272BEF6DCDA071D3F85BA9782336543B9D555F780CDD289E70E6A53A53&redirect%5Furi=https%3A%2F%2Fmyntuac%2Dmy%2Esharepoint%2Ecom%2F%5Fforms%2Fdefault%2Easpx&state=OD0w&claims=%7B%22id%5Ftoken%22%3A%7B%22xms%5Fcc%22%3A%7B%22values%22%3A%5B%22CP1%22%5D%7D%7D%7D&wsucxt=1&cobrandid=11bd8083%2D87e0%2D41b5%2Dbb78%2D0bc43c8a8e8a&client%2Drequest%2Did=e96553a1%2D00a5%2Da000%2D00b3%2Deec69eca899e">
                    {thankYouParticipantSheetTitle}
                  </a>
                  {thankYouLastTitle}
                </p>
                <h3 className="mt-3">{thankYouSubHeading}</h3>
                <div className="text-center">
                  <button
                    className="next-page login-btn"
                    onClick={handleContinueClick}
                  >
                    Continue
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankYou;
