import {
  LABEL_LIST_FAILURE,
  LABEL_LIST_REQUEST,
  LABEL_LIST_SUCCESS,
  PAGE_LIST_FAILURE,
  PAGE_LIST_REQUEST,
  PAGE_LIST_SUCCESS,
} from "../Constatnts/PageConstant";
import axios from "axios";

export const getPageList = (data) => async (dispatch) => {
  try {
    dispatch({ type: PAGE_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/page/get_all_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: PAGE_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: PAGE_LIST_FAILURE });
  }
};

export const getLabelList = (data) => async (dispatch) => {
  try {
    dispatch({ type: LABEL_LIST_REQUEST });
    const resData = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/label/get_all_data`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    dispatch({ type: LABEL_LIST_SUCCESS, payload: resData.data });
  } catch (err) {
    dispatch({ type: LABEL_LIST_FAILURE });
  }
};
