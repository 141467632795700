import React from "react";
import { Link, useLocation } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { getLabelList } from "../store/Action/PageAction";

const SuccessfullRegistrationPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { labelListData } = useSelector((state) => state.labelList);

  const registrationPath =
    location && location.pathname && location.pathname.slice(1);

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: registrationPath,
      })
    );
  }, [dispatch, registrationPath]);

  const thanksTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_SUCCESS_REGISTRATION_THANKS_TITLE"
    )?.vText;

  const thanksFormSubTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_SUCCESS_REGISTRATION_THANKS_SUB_TITLE"
    )?.vText;
  return (
    <div className="highlight-msg">
      <div className="login-form-logo">
        <Link to="/">
          <img
            src="assets/image/login-logo.png"
            alt="loginlogoimage"
            width="296"
            height="68"
          />
        </Link>
      </div>
      <div className="text">
        <h1>{thanksTitle}</h1>
        <p>{thanksFormSubTitle}</p>
        <div className="text-center mt-3">
          <Link to={"/"} className="login-btn">
            Continue
          </Link>
        </div>
      </div>
    </div>
  );
};

export default SuccessfullRegistrationPage;
