import React, { useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {
  getUserRegister,
  ResetRegisterState,
} from "../../store/Action/UserAction";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { getLabelList } from "../../store/Action/PageAction";

const Register = () => {
  const captchaRef = useRef(null);
  const dispatch = useDispatch();
  const toast = React.useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const googleSiteKey = "6LeYe08pAAAAAPJnjWVDU47L949QpyoidOH1PkZO";
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [selectType, setSelectType] = React.useState("");

  const [emailError, setEmailError] = React.useState("");
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");
  const [recaptchaError, setRecaptchaError] = React.useState("");
  const [selectTypeError, setSelectTypeError] = React.useState("");

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  const [recaptchaValue, setRecaptchaValue] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { userRegisterData } = useSelector((state) => state.userRegister);

  const { labelListData } = useSelector((state) => state.labelList);

  const registerPath =
    location && location.pathname && location.pathname.slice(1);

  
  React.useEffect(() => {
    const executeRecaptcha = async () => {
      try {
        const token = await captchaRef.current.executeAsync();
        setRecaptchaValue(token);
      } catch (error) {
        // console.log(error);
      }
    };

    executeRecaptcha();
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleType = (event) => {
    const newValue = event.target.value;
    setSelectType(newValue);
    if (newValue === "") {
      setSelectTypeError("Type is required.");
    } else {
      setSelectTypeError("");
    }
  };

  const handleEmailChange = (event) => {
    const userEmail = event.target.value.trim();
    setEmail(userEmail);

    event.target.value.trim() !== "" && setEmailError("");

    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (userEmail.trim() === "") {
      setEmailError("Email is required.");
    } else if (!emailRegex.test(userEmail)) {
      setEmailError("Invalid email format.");
    } else {
      setEmailError("");
    }
  };

  const handlePasswordChange = (event) => {
    const passwordValue = event.target.value.trim();
    setPassword(passwordValue);

    event.target.value.trim() !== "" && setPasswordError("");

    if (passwordValue.trim() === "" && passwordValue.length === 0) {
      setPasswordError("Pasword is Required.");
    } else if (passwordValue.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
    } else if (
      confirmPassword.length > 0 &&
      passwordValue !== confirmPassword
    ) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
    } else {
      setPasswordError("");
      setConfirmPasswordError("");
    }
  };

  const handleConfirmPasswordChange = (event) => {
    const userConfirmPassword = event.target.value.trim();
    setConfirmPassword(userConfirmPassword);

    event.target.value.trim() !== "" && setConfirmPasswordError("");

    if (userConfirmPassword.trim() === "" && userConfirmPassword.length === 0) {
      setConfirmPasswordError("Confirm Pasword is Required.");
    } else if (userConfirmPassword.length < 6) {
      setConfirmPasswordError(
        "Confirm Password must be at least 6 characters."
      );
    } else if (password.length > 0 && userConfirmPassword !== password) {
      setConfirmPasswordError(
        "New Password and Confirm Password do not match."
      );
    } else {
      setConfirmPasswordError("");
    }
  };

  React.useEffect(() => {
    dispatch(
      getLabelList({
        vPage: registerPath,
      })
    );
  }, [dispatch, registerPath]);

  const registerFormTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_REGISTER_RIGHT_TITLE"
    )?.vText;

  const registerFormSubTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_REGISTER_RIGHT_SUB_TITLE"
    )?.vText;

  const registerFormBannerTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_REGISTER_LEFT_TITLE"
    )?.vText;

  const registerFormBannerSubTitle =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_REGISTER_LEFT_SUB_TITLE"
    )?.vText;

  const registerSuccessToastMessage =
    labelListData &&
    labelListData.code === 200 &&
    labelListData.data &&
    labelListData.data.find(
      (title) => title.vLabel === "LBL_REGISTER_TOAST_MESSAGE"
    )?.vText;

  const isValidEmail = React.useCallback((email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  }, []);

  const validateSignUpForm = React.useCallback(() => {
    let isValid = true;

    setEmailError("");
    setPasswordError("");
    setConfirmPasswordError("");
    setRecaptchaError("");
    setSelectTypeError("");

    if (email.length === 0) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!isValidEmail(email)) {
      setEmailError("Invalid Email Format.");
      isValid = false;
    }

    if (password.length === 0) {
      setPasswordError("Pasword is Required.");
      isValid = false;
    } else if (password.length < 6) {
      setPasswordError("Password must be at least 6 characters.");
      isValid = false;
    }

    if (confirmPassword.length === 0) {
      setConfirmPasswordError("Confirm Pasword is Required.");
      isValid = false;
    } else if (confirmPassword.length < 6) {
      setConfirmPasswordError(
        "Confirm Password must be at least 6 characters."
      );
      isValid = false;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Password and Confirm password do not match.");
      isValid = false;
    }

    if (!recaptchaValue) {
      setRecaptchaError("ReCAPTCHA verification failed. Please try again.");
      isValid = false;
    }

    if (!selectType) {
      setSelectTypeError("Type is required.");
      isValid = false;
    }

    return isValid;
  }, [
    email,
    password,
    confirmPassword,
    recaptchaValue,
    selectType,
    isValidEmail,
  ]);

  const signUpUser = React.useCallback(
    async (e) => {
      e.preventDefault();
      // setLoading(true);

      let newToken;
      try {
        if (captchaRef.current) {
          newToken = await captchaRef.current.executeAsync();
          setRecaptchaValue(newToken);
        } else {
          setRecaptchaError("ReCAPTCHA failed to load. Please try again.");
        }
      } catch (error) {
        setRecaptchaError("ReCAPTCHA verification failed. Please try again.");
        setLoading(false);

        // console.error("ReCAPTCHA error:", error);
        return;
      }

      const isValideData = validateSignUpForm();

      if (isValideData) {
        dispatch(
          getUserRegister({
            vEmail: email,
            vPassword: password,
            cPassword: confirmPassword,
            vGoogleRecaptchaResponse: newToken,
            eType: selectType,
          })
        );
        setLoading(true);
      } else {
        setLoading(false);
      }
    },
    [
      dispatch,
      email,
      password,
      confirmPassword,
      // recaptchaValue,
      selectType,
      validateSignUpForm,
    ]
  );

  React.useEffect(() => {
    if (userRegisterData && userRegisterData.code === 201) {
      dispatch(ResetRegisterState());
      toast.current.show({
        severity: "success",
        summary: registerSuccessToastMessage,
        life: 2000,
        className: "custom-toast",
      });
      setTimeout(() => {
        setEmail("");
        setPassword("");
        setConfirmPassword("");
        setRecaptchaValue("");
        setSelectTypeError("");
        setLoading(false);

        navigate("/success-registration");
      }, 2000);
    } else if (userRegisterData && userRegisterData.code === 400) {
      setLoading(false);
      dispatch(ResetRegisterState());
      toast.current.show({
        severity: "error",
        summary: userRegisterData.message,
        life: 2000,
        className: "custom-toast-error",
      });
      setTimeout(() => {
        setLoading(false);
        // setEmail("");
        // setPassword("");
        // setConfirmPassword("");
        // setRecaptchaValue("");
        // setSelectTypeError("");
      }, 2000);
    }
  }, [userRegisterData, navigate, dispatch,registerSuccessToastMessage]);

  return (
    <section className="login">
      <Toast ref={toast} />
      <div className="container">
        <div className="row g-0">
          <div className="col-lg-6">
            <div className="login-img">
              <img
                src="assets/image/signup.jpg"
                alt="signup"
                width="800"
                height="791"
              />
              <div className="login-img-content">
                <h1 className="title">{registerFormBannerTitle}</h1>
                <p className="desc">{registerFormBannerSubTitle}</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="login-right-view">
              <div className="login-form-logo">
                <Link to="/">
                  <img
                    src="assets/image/login-logo.png"
                    alt="loginLogo"
                    width="296"
                    height="68"
                  />
                </Link>
              </div>
              <div className="login-form-content">
                <h2 className="title">{registerFormTitle}</h2>
                <p className="desc">{registerFormSubTitle}</p>
              </div>
              <form onSubmit={signUpUser}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-box">
                      <label htmlFor="exampleType" className="form-label">
                        Select Type
                      </label>
                      <select
                        className="form-select form-control"
                        aria-label="Default select example"
                        id="exampleType"
                        value={selectType}
                        onChange={handleType}
                      >
                        <option value="">Choose Type</option>
                        <option value="Organisation">Organisation</option>
                        <option value="Academic Researcher">
                          Academic Researcher
                        </option>
                      </select>
                      {selectTypeError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {selectTypeError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputEmail1"
                        className="form-label"
                      >
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Email Address"
                        value={email}
                        onChange={handleEmailChange}
                      />
                      {emailError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {emailError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Password
                      </label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter Password"
                        value={password}
                        onChange={handlePasswordChange}
                      />
                      <i
                        className={`toggle-password far ${
                          showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={togglePasswordVisibility}
                      ></i>
                      {passwordError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {passwordError}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-box">
                      <label
                        htmlFor="exampleInputPassword1"
                        className="form-label"
                      >
                        Confirm Password
                      </label>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter Confirm Password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                      />
                      <i
                        className={`toggle-password far ${
                          showConfirmPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                        onClick={toggleConfirmPasswordVisibility}
                      ></i>
                      {confirmPasswordError && (
                        <div
                          className="error-msg"
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "5px",
                          }}
                        >
                          {confirmPasswordError}
                        </div>
                      )}
                    </div>
                  </div>
                  <ReCAPTCHA
                    ref={captchaRef}
                    sitekey={googleSiteKey}
                    size="invisible"
                  />
                  {recaptchaError && (
                    <div
                      className="error-msg"
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "5px",
                        marginBottom: "10px",
                      }}
                    >
                      {recaptchaError}
                    </div>
                  )}
                  <div className="col-md-12">
                    <div className="login-btn-view">
                      {loading ? (
                        <>
                          {" "}
                          <button className="login-btn" type="button" disabled>
                            <span
                              className="spinner-border spinner-border-sm me-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading...
                          </button>
                        </>
                      ) : (
                        <>
                          {" "}
                          <button type="submit" className="login-btn">
                            Sign Up
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <p className="create-account">
                  Already have an account ?
                  <Link className="text" to="/login">
                    Log In
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
